import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import countries from 'world-countries';

const countryCodes = countries
    .map((country) => ({
        code: country.cca2,
        name: country.name.common,
        dialCode: `+${country.idd.root}${country.idd.suffixes[0] || ''}`.replace(/\+/, ''),
    }))
    .sort((a, b) => b.dialCode.length - a.dialCode.length);

const motions = {
    label: {
        initial: { top: '50%', translateY: '-50%' },
        animate: (isFocusedOrHasValue) => ({
            top: isFocusedOrHasValue ? 0 : '50%',
            translateY: '-50%',
        }),
        transition: { duration: 0.2 },
    },
    dropdown: {
        initial: { opacity: 0, y: -10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 },
        transition: { duration: 0.2 },
    },
};

const PhoneField = ({ name, value, onChange, className, label, error, ...properties }) => {
    const [selectedCountry, setSelectedCountry] = useState(countryCodes.find((c) => c.dialCode === '+31') || countryCodes[0]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const searchInputRef = useRef(null);
    const dropdownToggleRef = useRef(null);

    const filteredCountries = useMemo(() => {
        return countryCodes.filter((country) => country.name.toLowerCase().includes(searchTerm.toLowerCase()) || country.code.toLowerCase().includes(searchTerm.toLowerCase()) || country.dialCode.includes(searchTerm));
    }, [searchTerm]);

    const stripDialCode = useCallback((fullNumber) => {
        for (let country of countryCodes) {
            if (fullNumber.startsWith(country.dialCode)) {
                return { strippedNumber: fullNumber.slice(country.dialCode.length), country };
            }
        }
        return { strippedNumber: fullNumber, country: null };
    }, []);

    const handleCountryChange = useCallback(
        (country) => {
            setSelectedCountry(country);
            setIsDropdownOpen(false);
            updatePhoneValue(phoneNumber, country.dialCode);
            inputRef.current?.focus();
        },
        [phoneNumber],
    );

    const handlePhoneChange = useCallback(
        (event) => {
            const newPhoneNumber = event.target.value.replace(/\D/g, '');
            setPhoneNumber(newPhoneNumber);
            updatePhoneValue(newPhoneNumber, selectedCountry.dialCode);
        },
        [selectedCountry],
    );

    const updatePhoneValue = useCallback(
        (number, dialCode) => {
            const fullNumber = `${dialCode}${number}`;
            onChange && onChange({ target: { name, value: fullNumber } });
        },
        [onChange, name],
    );

    const handleDropdownToggle = useCallback(() => {
        setIsDropdownOpen((prev) => !prev);
        setSearchTerm('');
        setFocusedIndex(-1);
    }, []);

    const handleSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value);
        setFocusedIndex(-1);
    }, []);

    const handleKeyDown = useCallback(
        (event) => {
            if (isDropdownOpen) {
                switch (event.key) {
                    case 'ArrowDown':
                        event.preventDefault();
                        setFocusedIndex((prev) => (prev + 1) % filteredCountries.length);
                        break;
                    case 'ArrowUp':
                        event.preventDefault();
                        setFocusedIndex((prev) => (prev - 1 + filteredCountries.length) % filteredCountries.length);
                        break;
                    case 'Enter':
                        event.preventDefault();
                        if (focusedIndex !== -1) {
                            handleCountryChange(filteredCountries[focusedIndex]);
                        }
                        break;
                    case 'Escape':
                        setIsDropdownOpen(false);
                        inputRef.current?.focus();
                        break;
                }
            }
        },
        [isDropdownOpen, filteredCountries, focusedIndex, handleCountryChange],
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isDropdownOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        if (value) {
            const { strippedNumber, country } = stripDialCode(value);
            if (country) {
                setSelectedCountry(country);
                setPhoneNumber(strippedNumber);
            } else {
                setPhoneNumber(value);
            }
        } else {
            setPhoneNumber('');
        }
    }, [value, stripDialCode]);

    const hasValue = phoneNumber.length > 0;
    const borderColor = error ? 'border-crimson-red' : 'border-[#34313266]';
    const labelColor = error ? 'text-crimson-red' : 'text-[#212121]';

    return (
        <div className={twMerge('relative w-full rounded-lg border bg-pure-white', borderColor, className)} ref={dropdownRef}>
            <div className='flex min-h-[56px] w-full items-center bg-transparent px-4 py-2' onClick={() => inputRef.current?.focus()}>
                <div className='mr-2 flex items-center'>
                    <span className='mr-1'>{selectedCountry.dialCode}</span>
                    <motion.button
                        ref={dropdownToggleRef}
                        onClick={(e) => {
                            e.preventDefault();
                            handleDropdownToggle();
                        }}
                        className='focus:outline-none'
                        tabIndex='-1'
                    >
                        <motion.svg className='size-5 text-deep-black' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' animate={{ rotate: isDropdownOpen ? 180 : 0 }} transition={{ duration: 0.2 }}>
                            <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                        </motion.svg>
                    </motion.button>
                </div>
                <input ref={inputRef} type='tel' value={phoneNumber} onChange={handlePhoneChange} className='grow bg-transparent focus:outline-none' placeholder=' ' onFocus={() => setIsFocused(true)} onBlur={() => !isDropdownOpen && setIsFocused(false)} onKeyDown={handleKeyDown} {...properties} />
            </div>

            <motion.label className={twMerge('pointer-events-none absolute left-1 bg-pure-white px-3 rounded-t-full', labelColor)} initial={motions.label.initial} animate={motions.label.animate(isFocused || hasValue)} transition={motions.label.transition}>
                {label}
            </motion.label>

            <AnimatePresence>
                {isDropdownOpen && (
                    <motion.div className='absolute z-10 mt-2 w-full rounded-md border border-[#34313266] bg-pure-white shadow-lg' style={{ top: 'calc(100% + 8px)' }} {...motions.dropdown}>
                        <input ref={searchInputRef} type='text' placeholder='Zoeken...' value={searchTerm} onChange={handleSearchChange} className='w-full border-b border-[#34313266] bg-transparent p-4' />
                        <ul className='max-h-60 overflow-y-auto'>
                            {filteredCountries.map((country, index) => (
                                <li key={country.code} className={`flex h-[56px] cursor-pointer items-center justify-between px-4 ${index === focusedIndex ? 'bg-[#E8F0FE]' : ''} hover:bg-[#E6E6E6] active:bg-[#D9D9D9]`} onClick={() => handleCountryChange(country)}>
                                    <span>
                                        {country.name} ({country.dialCode})
                                    </span>
                                    {country.code === selectedCountry.code && <span className='size-2 rounded-full bg-deep-black'></span>}
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PhoneField;
