import React from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export const ToggleButton = ({ children, selected, onClick, className }) => {
    return (
        <motion.button type='button' className={twMerge('relative px-6 py-2 border border-[#B1C6CF] rounded-full font-semibold', selected ? 'text-pure-white bg-[#212121] border-[#212121]' : 'hover:bg-grey-100', className)} onClick={onClick} whileTap={{ scale: 0.95 }}>
            {children}
        </motion.button>
    );
};

export const ToggleButtonGroupField = ({ value, onChange, className, options, name, multiple = false, label }) => {
    const handleChange = (optionValue) => {
        let newValue;
        if (multiple) {
            newValue = Array.isArray(value) ? [...value] : [];
            const index = newValue.indexOf(optionValue);
            if (index === -1) {
                newValue.push(optionValue);
            } else {
                newValue.splice(index, 1);
            }
        } else {
            newValue = optionValue === value ? null : optionValue;
        }

        onChange({
            target: {
                name,
                value: newValue,
            },
        });
    };

    const isSelected = (optionValue) => {
        if (multiple) {
            return Array.isArray(value) && value.includes(optionValue);
        }
        return optionValue === value;
    };

    return (
        <div className='flex flex-col gap-2'>
            {label && <label className='font-semibold text-deep-black'>{label}</label>}
            <div className={twMerge('inline-flex flex-wrap gap-2', className)} role='group'>
                {options.map((option) => (
                    <ToggleButton key={option.value} selected={isSelected(option.value)} onClick={() => handleChange(option.value)}>
                        {option.label}
                    </ToggleButton>
                ))}
            </div>
        </div>
    );
};
