import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as ErrorIcon } from '@assets/icons/error.svg';

const variants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
};

const ErrorItem = ({ message }) => (
    <motion.div variants={variants} initial='initial' animate='animate' exit='exit' transition={{ duration: 0.2, ease: 'easeInOut' }} className='flex items-center gap-1.5 text-sm text-crimson-red'>
        <ErrorIcon className={'h-5 min-w-5'} />
        <p>{message}</p>
    </motion.div>
);

const Error = ({ error }) => {
    const renderErrors = () => {
        if (typeof error === 'string') {
            return [<ErrorItem key='single' message={error} />];
        } else if (Array.isArray(error)) {
            return error.map((err, index) => <ErrorItem key={index} message={err} />);
        } else if (typeof error === 'object' && error !== null) {
            return Object.entries(error).map(([key, value]) => <ErrorItem key={key} message={value} />);
        }
        return null;
    };

    return (
        <AnimatePresence>
            <div className='flex flex-col gap-1'>{renderErrors()}</div>
        </AnimatePresence>
    );
};

export default Error;
